import {ProfileData} from "../components/profile/profile";
import {AboutData} from "../components/about";
import {CertificatesData} from "../components/certification";
import {LanguageData} from "../support/LanguageData";
import {ProjectData} from "../components/project";
import {ExperienceData} from "../components/experience";
import {NavDataItem} from "../components/nav/custom-nav";
import {AchievementData} from "../components/achievement";

export class DataService {

    //static apiUrl = "http://localhost:3000";

    static apiUrl = ".";

    public static async getProfile(): Promise<ProfileData> {
        const response = await fetch(this.apiUrl + "/data/profileData.json");
        return await response.json();
    }

    public static async getAbout(): Promise<LanguageData<AboutData>> {
        const response = await fetch(this.apiUrl + "/data/aboutData.json");
        return await response.json();
    }

    public static async getCertificates(): Promise<CertificatesData[]> {
        const response = await fetch(this.apiUrl + "/data/certificateData.json");
        return await response.json();
    }

    public static async getAchievements(): Promise<LanguageData<AchievementData[]>> {
        const response = await fetch(this.apiUrl + "/data/achievementData.json");
        return await response.json();
    }

    public static async getExperiences(): Promise<ExperienceData[]> {
        const response = await fetch(this.apiUrl + "/data/experienceData.json");
        return await response.json();
    }

    public static async getNavData(): Promise<LanguageData<NavDataItem[]>> {
        const response = await fetch(this.apiUrl + "/data/navData.json");
        return await response.json();
    }

    public static async getProjects(): Promise<LanguageData<ProjectData[]>> {
        const response = await fetch(this.apiUrl + "/data/projectData.json");
        return await response.json();
    }

    public static async getCustomLangData(): Promise<LanguageData<LanguageData<string>>> {
        const response = await fetch(this.apiUrl + "/data/customData.json");
        return await response.json();
    }
}
