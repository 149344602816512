import {ForwardedRef, forwardRef} from "react";
import {NavDataItem} from "./nav/custom-nav";
import {LanguageData} from "../support/LanguageData";

export interface AboutData {
    experience: string[];
    education: string[];
    text: string;
}

export const About = forwardRef(
    ({
         aboutData,
         navDataItem,
         languageData
     }: {
         aboutData: AboutData | undefined,
         navDataItem: NavDataItem | undefined,
         languageData: LanguageData<string>
     },
     ref: ForwardedRef<HTMLElement>) => {
        return (
            <section ref={ref} id={navDataItem?.href?.replace("#", "")} className="relative">
                <p className="text-center">{navDataItem?.subTitle}</p>
                <h1 className="text-center text-5xl">{navDataItem?.name}</h1>
                <div className="my-8 flex gap-8 max-sm:flex-wrap justify-center">
                    <div className="flex h-[20rem] w-[20rem]">
                        <img src="./assets/about-pic.png"
                             alt="Profile"
                        />
                    </div>
                    <div className="flex grow flex-col ">
                        <div className="flex gap-8">
                            <div
                                className="flex flex-1 flex-col items-center justify-center border-2 rounded-3xl text-center p-4">
                                <img src="./assets/experience.png"
                                     alt="Experience icon"
                                     className="h-8 mx-auto"
                                />
                                <h3>{(languageData || {})["experience"]}</h3>
                                {(aboutData?.experience || []).map((experience) => (
                                    <p key={experience}>{experience}</p>
                                ))}
                            </div>
                            <div
                                className="flex flex-1 flex-col items-center justify-center border-2 rounded-3xl text-center p-4">
                                <img
                                    src="./assets/education.png"
                                    alt="Education icon"
                                    className="h-8 mx-auto"
                                />
                                <h3>{(languageData || {})["education"]}</h3>
                                {(aboutData?.education || []).map((education) => (
                                    <p key={education}>{education}</p>
                                ))}
                            </div>
                        </div>
                        <div className="text-justify mt-3">
                            <p>
                                {aboutData?.text}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
)
