import {Timeline} from "./timeline/timeline";
import {ForwardedRef, forwardRef} from "react";
import {NavDataItem} from "./nav/custom-nav";

export interface ProjectData {
    title?: string;
    description?: string;
    year: number;
    color: string;
    items: ProjectItemData[];
}

export interface ProjectItemData {
    title: string;
    wasGreenfield: boolean;
    description?: string;
    numberOfPeopleAtTeam?: number;
    durationMonths?: number;
    technologies?: string[];
    projectManagement?: string;
    role: string[];
}

export const Project = forwardRef(
    ({projectData, navDataItem}: {
        projectData: ProjectData[] | undefined,
        navDataItem: NavDataItem | undefined
    }, ref: ForwardedRef<HTMLElement>) => {
        return (
            <section ref={ref} id={navDataItem?.href.replace("#", "")} className="relative">
                <p className="text-center">{navDataItem?.subTitle}</p>
                <h1 className="text-center text-5xl">{navDataItem?.name}</h1>
                <div className="flex my-8">
                    <Timeline projectData={
                        (projectData || []).sort((a, b) => a.year - b.year)
                    }/>
                </div>
            </section>
        );
    }
)
