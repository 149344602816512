import {CSSProperties} from "react";
import './timeline.css';
import {ProjectData} from "../project";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

const cssVars = (accentColor: string) => {
    return {'--accent-color': accentColor} as CSSProperties
}

export const Timeline = ({projectData}: { projectData: ProjectData[] }) => {
    return (
        <div className="timeline">
            <ul>
                {(projectData || []).map((data, index) => (
                    <TimelineItem key={index} projectData={data}/>
                ))}
            </ul>
        </div>
    );
};

const TimelineItem = ({projectData}: { projectData: ProjectData }) => {
    return (
        <li style={cssVars(projectData.color)}>
            <div className="date">{projectData.year}</div>
            {projectData.items?.map((item, index) => (
                    <div key={index} className="p-2">
                        <div className="title"><FontAwesomeIcon icon={faCircleInfo} /> {item.title} </div>
                        <div className="descr">{item.description}</div>
                        <div className="flex flex-wrap justify-center gap-2">
                            {item.technologies && (item.technologies.sort((a1, a2) => a1.localeCompare(a2)).map((technology, index) => (
                                <div key={index}
                                    className="relative grid select-none items-center whitespace-nowrap rounded-lg border border-gray-900 py-1.5 px-3 font-sans text-xs font-bold uppercase text-gray-700">
                                    <span>{technology}</span>
                                </div>
                            )))}
                        </div>
                    </div>
                )
            )}
        </li>
    )
}
