import {ForwardedRef, forwardRef} from "react";
import {NavDataItem} from "./nav/custom-nav";

export interface AchievementData {
    title: string;
    description: string;
    link: string;
    isPicture: boolean;
    year: number;
}

export const Achievement = forwardRef(
    ({
         achievementsData,
         navDataItem
     }: {
        achievementsData: AchievementData[] | undefined,
        navDataItem: NavDataItem | undefined
    }, ref: ForwardedRef<HTMLElement>) => {

        return (
            <section ref={ref} id={navDataItem?.href.replace("#", "")} className="relative">
                <p className="text-center">{navDataItem?.subTitle}</p>
                <h1 className="text-center text-5xl">{navDataItem?.name}</h1>
                <div className="my-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 grid-flow-row gap-4">
                        {
                            (achievementsData || []).sort((a, b) => a.year - b.year).map((achievementData) => (
                                <div key={achievementData.link}
                                     className="bg-white border border-gray-200 rounded-lg shadow">
                                    {achievementData.isPicture ?
                                        <img className="w-full h-[20rem]" src={achievementData.link}
                                             alt={achievementData.title}/> :
                                        <iframe
                                            className="mx-auto w-full h-[20rem]"
                                            title={achievementData.title}
                                            src={achievementData.link}
                                        />
                                    }
                                    <div className="p-5">
                                        <a href={achievementData.link}>
                                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{achievementData.title}</h5>
                                        </a>
                                        <p className="mb-3 font-normal text-gray-700">{achievementData.description}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        )
    })
