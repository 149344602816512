import {Skill} from "./skill";
import {ForwardedRef, forwardRef} from "react";
import {NavDataItem} from "./nav/custom-nav";
import {LanguageData} from "../support/LanguageData";

export interface ExperienceData {
    titleKey: string;
    experienceDataItems: ExperienceDataItem[];
}

export interface ExperienceDataItem {
    name: string;
    skillTime: number;
    level: number;
}

export const Experience = forwardRef(
    ({experienceData, navDataItem, languageData}: {
        experienceData: ExperienceData[],
        navDataItem: NavDataItem | undefined
        languageData: LanguageData<string>
    }, ref: ForwardedRef<HTMLElement>) => {
        return (
            <section ref={ref} id={navDataItem?.href.replace("#", "")} className="relative">
                <p className="text-center">{navDataItem?.subTitle}</p>
                <h1 className="text-center text-5xl">{navDataItem?.name}</h1>
                <div className="flex flex-wrap my-8 gap-8">
                    {experienceData.map((experienceData) => (
                        <div key={experienceData.titleKey}
                             className="p-5 flex-1 bg-white rounded-3xl border-x-[0.1rem] border-y-[0.1rem] border-solid text-center">
                            <h2 className="font-semibold text-2xl text-neutral-500 mb-3">{{...languageData || {}}[experienceData.titleKey || ""]}</h2>
                            <div className="w-full">
                                <Skill experienceDataItems={experienceData.experienceDataItems}/>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        )
    })
