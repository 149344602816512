import './App.css';
import {CustomNav, NavDataItem} from "./components/nav/custom-nav";
import {Profile, ProfileData} from "./components/profile/profile";
import {About, AboutData} from "./components/about";
import {Experience, ExperienceData} from "./components/experience";
import {Project, ProjectData} from "./components/project";
import {Contact, ContactData} from "./components/contact";
import {CustomFooter} from "./components/footer/custom-footer";
import {Certificates, CertificatesData} from "./components/certification";
import {Achievement, AchievementData} from "./components/achievement";
import {ArrowHref, ArrowHrefPosition} from "./components/arrow-href";
import useScroll from "./components/useScroll";
import React, {useEffect, useState} from "react";
import {DataService} from "./services/DataService";
import {LanguageData} from "./support/LanguageData";
import {Languages} from "./components/language";
import {Loader} from "./components/loader";
import {CookiesConsent} from "./components/cookiesConsent";

const numberOfWorkingYears = new Date().getFullYear() - 2015;
const email = 'info@juraj-fojtik.com';

function getHref(tops: number[], navDataItems: NavDataItem[] | undefined, toDown: boolean): string {
    if (navDataItems === undefined || navDataItems?.length === 0)
        return '';

    for (let i = 0; i < tops.length; i++) {
        if (i === 0)
            tops[i] = tops[i] - 100;
        else
            tops[i] = tops[i] + 1;
    }

    let index = 0;
    let min = Number.MAX_VALUE;
    for (let i = 0; i < tops.length; i++) {
        if (tops[i] > 0 && tops[i] < min) {
            min = tops[i];
            index = i;
        }
    }

    if (!toDown && index > 0) {
        return navDataItems[index - 1].href;
    } else if (toDown && index < tops.length - 1) {
        return navDataItems[index + 1].href;
    }

    return '';
}

function App() {

    function incCounter() {
        loadedCounter++;
        if (loadedCounter >= 8) {
            setLoaded(true);
        }
    }

    let [lang, setLang] = useState<Languages>(Languages.EN);
    let [langData, setLangData] = useState<LanguageData<LanguageData<string>>>({});
    let [projectData, setProjectData] = useState<LanguageData<ProjectData[]>>({});
    let [profileData, setProfileData] = useState<ProfileData>();
    let [experienceData, setExperienceData] = useState<ExperienceData[]>([]);
    let [aboutData, setAboutData] = useState<LanguageData<AboutData>>({});
    let [contactData, setContactData] = useState<ContactData>();
    let [certificatesData, setCertificatesData] = useState<CertificatesData[]>([]);
    let [videosData, setVideosData] = useState<LanguageData<AchievementData[]>>({});
    let [navDataItems, setNavDataItems] = useState<LanguageData<NavDataItem[]>>({});
    let [loaded, setLoaded] = useState(false);

    const [topProfile, profileRef] = useScroll<HTMLDivElement>();
    const [topAbout, aboutRef] = useScroll<HTMLDivElement>();
    const [topExperience, experienceRef] = useScroll<HTMLDivElement>();
    const [topCertificate, certificateRef] = useScroll<HTMLDivElement>();
    const [topProject, projectRef] = useScroll<HTMLDivElement>();
    const [topAchievement, achievementRef] = useScroll<HTMLDivElement>();
    const [topContact, contactRef] = useScroll<HTMLDivElement>();

    let loadedCounter = 0;

    useEffect(() => {
        const userLang = navigator.language;
        if (userLang === 'sk-SK' || userLang === 'sk') {
            setLang(Languages.SK)
        } else {
            setLang(Languages.EN)
        }
    }, [])

    useEffect(() => {
        DataService.getProfile().then((data) => {
            setProfileData(data);
            setContactData({email, socialLinks: data?.socialLinks || []})
            incCounter()
        });
        DataService.getExperiences().then((data) => {
            setExperienceData(data);
            incCounter()
        });
        DataService.getAbout().then((data) => {
            data['en'].experience = data['en'].experience.map((experience) => {
                return experience.replace('??{workingYears}??', numberOfWorkingYears.toString());
            });
            data['sk'].experience = data['sk'].experience.map((experience) => {
                return experience.replace('??{workingYears}??', numberOfWorkingYears.toString());
            });
            data['en'].text = data['en'].text.replace('??{workingYears}??', numberOfWorkingYears.toString());
            data['sk'].text = data['sk'].text.replace('??{workingYears}??', numberOfWorkingYears.toString());
            setAboutData(data);
            incCounter()
        });
        DataService.getProjects().then((data) => {
            setProjectData(data)
            incCounter()
        })
        DataService.getCertificates().then((data) => {
            setCertificatesData(data)
            incCounter()
        })
        DataService.getAchievements().then((data) => {
            setVideosData(data)
            incCounter()
        })
        DataService.getNavData().then((data) => {
            setNavDataItems(data)
            incCounter()
        })
        DataService.getCustomLangData().then((data) => {
            setLangData(data)
            incCounter()
        });
    }, []);

    return (
        <div className="App">
            <Loader isLoading={!loaded}/>
            {loaded && (<CookiesConsent lang={lang} langData={langData}/>)}
            <div className={`ease-in duration-300 ${!loaded && 'hidden'}`}>
                {loaded && (
                    <div>
                        <ArrowHref position={ArrowHrefPosition.TOP}
                                   href={
                                       getHref([
                                           topProfile?.top || 0,
                                           topAbout?.top || 0,
                                           topExperience?.top || 0,
                                           topCertificate?.top || 0,
                                           topProject?.top || 0,
                                           topAchievement?.top || 0,
                                           topContact?.top || 0
                                       ], navDataItems[lang], false)}/>
                        <CustomNav language={lang} setLanguage={setLang}
                                   navData={{title: profileData?.name || '', navItems: navDataItems[lang]}}/>
                        <Profile ref={profileRef} profileData={profileData} languageData={langData[lang]} lang={lang}/>
                        <About ref={aboutRef} navDataItem={navDataItems[lang][1]} aboutData={aboutData[lang]}
                               languageData={langData[lang]}/>
                        <Experience ref={experienceRef} navDataItem={navDataItems[lang][2]}
                                    experienceData={experienceData} languageData={langData[lang]}/>
                        <Certificates ref={certificateRef} navDataItem={navDataItems[lang][3]}
                                      certificatesData={certificatesData}/>
                        <Project ref={projectRef} navDataItem={navDataItems[lang][4]} projectData={projectData[lang]}/>
                        <Achievement ref={achievementRef} navDataItem={navDataItems[lang][5]}
                                     achievementsData={videosData[lang]}/>
                        <Contact ref={contactRef} navDataItem={navDataItems[lang][6]} contactData={contactData}/>
                        <CustomFooter navData={{title: profileData?.name || '', navItems: navDataItems[lang]}}
                                      languageData={langData[lang]}/>
                        <ArrowHref position={ArrowHrefPosition.BOTTOM} href={
                            getHref([
                                topProfile?.top || 0,
                                topAbout?.top || 0,
                                topExperience?.top || 0,
                                topCertificate?.top || 0,
                                topProject?.top || 0,
                                topAchievement?.top || 0,
                                topContact?.top || 0
                            ], navDataItems[lang], true)}/>
                    </div>
                )}
            </div>

        </div>
    );
}

export default App;
