import {ForwardedRef, forwardRef} from "react";
import {NavDataItem} from "./nav/custom-nav";

export interface CertificatesData {
    image?: string;
    title: string;
    description?: string;
    link?: string;
}

export const Certificates = forwardRef(
    ({
         certificatesData,
         navDataItem
     }: {
        certificatesData: CertificatesData[],
        navDataItem: NavDataItem | undefined
    }, ref: ForwardedRef<HTMLElement>) => {
        return (
            <section ref={ref} id={navDataItem?.href.replace("#","")} className="relative">
                <p className="text-center">{navDataItem?.subTitle}</p>
                <h1 className="text-center text-5xl">{navDataItem?.name}</h1>
                <div className="grid-cols-1 md:grid-cols-3 grid my-8 gap-8">
                    {certificatesData.map((certificateData) => (
                        <div key={certificateData.title} className="border-2 p-5 rounded-2xl">
                            {certificateData.image && <img src="./assets/certificate.png"
                                                           alt="Certificate icon"
                                                           className="icon mx-auto"/>}
                            <h3><a href={certificateData.link}>{certificateData.title}</a></h3>
                            {certificateData.description && <p>{certificateData.description}</p>}
                        </div>
                    ))}
                </div>
            </section>
        )
    }
)
