import './profile.css';
import {ForwardedRef, forwardRef} from "react";
import {LanguageData} from "../../support/LanguageData";
import {Languages} from "../language";

export interface ProfileData {
    name: string;
    profession: string;
    socialLinks: SocialLink[];
}

export interface SocialLink {
    icon: string;
    link: string;
    alt: string;
    name: string;
}

export const Profile = forwardRef(
    (
        {profileData, languageData, lang}: {
            profileData: ProfileData | undefined,
            lang: Languages
            languageData: LanguageData<string>
        }, ref: ForwardedRef<HTMLElement>) => {
        return (
            <section ref={ref} id="profile" className="relative flex items-center justify-center min-h-[83vh]">
                <div className="flex items-center justify-center gap-10 max-md:flex-wrap mb-44">
                    <div className="flex w-64 h-64 md:w-96 md:h-96">
                        <img src="./assets/profile-pic.png" alt={`${profileData?.name}`}/>
                    </div>
                    <div className="flex text-center flex-col">
                        <p className="text-center">{(languageData || {})["hello-profile"]}</p>
                        <h1 className="text-center text-3xl md:text-5xl">{profileData?.name}</h1>
                        <p className="text-2xl md:text-3xl mb-4 mt-1">{profileData?.profession}</p>
                        <div className="flex justify-center gap-5">
                            <button
                                className="btn btn-color-2"
                                onClick={() => {
                                    if (lang === Languages.SK) window.open('./assets/cv_sk.pdf')
                                    else window.open('./assets/cv_en.pdf')
                                }}
                            >
                                {(languageData || {})["download-cv"]}
                            </button>
                            <button
                                className="btn btn-color-1"
                                onClick={() => window.location.href = './#contact'}
                            >
                                {(languageData || {})["contact-info"]}
                            </button>
                        </div>
                        <div className="flex flex-row justify-center gap-4 mt-4">
                            {
                                (profileData?.socialLinks || []).map((socialLink) => (
                                    <div key={socialLink.link} className="h-11 w-11 flex items-center justify-center">
                                        <img
                                            src={`./assets/${socialLink.icon}`}
                                            alt={socialLink.alt}
                                            className="h-10 w-10 cursor-pointer hover:h-9 hover:w-9"
                                            onClick={() => window.location.href = socialLink.link}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    }
)
