import React from "react";
import "./custom-footer.css";
import {NavData} from "../nav/custom-nav";
import {LanguageData} from "../../support/LanguageData";

export const CustomFooter = ({navData, languageData}: { navData: NavData | undefined, languageData: LanguageData<string> }) => {
    const year = new Date().getFullYear();
    return (
        <footer className="nav-footer">
            <nav>
                <div className="nav-links-container">
                    <ul className="nav-links">
                        {
                            navData?.navItems?.map((navItem) => (
                                <li key={`footer-${navItem?.href}`}><a href={navItem.href}>{navItem.name}</a></li>
                            ))
                        }
                    </ul>
                </div>
            </nav>
            <p>Copyright &#169; {year} {navData?.title}. {(languageData ||{})['all-right-reserved']}</p>
        </footer>
    )
}
