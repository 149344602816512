import {createRef, MutableRefObject, useEffect, useState} from 'react'

/**
 * Check if an element is in viewport
 */
export default function useScroll<T>(): [DOMRect | undefined, MutableRefObject<T>] {
    const [boundingClientRect, setBoundingClientRect] = useState<DOMRect>()
    const currentElement: MutableRefObject<any> = createRef()

    const onScroll = () => {
        if (currentElement.current) {
            setBoundingClientRect(currentElement.current.getBoundingClientRect())
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', onScroll, true)
        return () => document.removeEventListener('scroll', onScroll, true)
    })

    return [boundingClientRect, currentElement]
}
