import React, {useState} from "react";
import {Languages} from "./language";
import {LanguageData} from "../support/LanguageData";


export const CookiesConsent = ({lang, langData}: { lang: Languages, langData: LanguageData<LanguageData<string>> }) => {
    const [show, setShow] = useState<boolean>(true);

    const loadGoogleAnalyticsScript = () => {
        const body = document.body;

        const googletagmanagerScript = document.createElement('script');
        googletagmanagerScript.async = true;
        googletagmanagerScript.src = `https://www.googletagmanager.com/gtag/js?id=G-C0NV0N30CD`;
        body.appendChild(googletagmanagerScript);

        const gtagScript = document.createElement('script');
        gtagScript.textContent = `window.dataLayer = window.dataLayer || [];
                                  function gtag(){dataLayer.push(arguments);}
                                  gtag('js', new Date());
                                  gtag('config', 'G-C0NV0N30CD');`;
        body.appendChild(gtagScript);
    }

    return (
        <div className={`wrapper ${show && 'show'}`}>
            <header>
                <img className="w-7 h-7" src="./assets/cookie.png" alt="cookie-icon"/>
                <h2>Cookies</h2>
            </header>

            <div className="data">
                <p>{(langData || {})[lang]['cookies-text']}</p>
            </div>

            <div className="buttons">
                <button className="button" onClick={() => {
                    setShow(false)
                    loadGoogleAnalyticsScript()
                }}>{(langData || {})[lang]['accept']}
                </button>
                <button className="button" onClick={() => setShow(false)}>{(langData || {})[lang]['decline']}</button>
            </div>
        </div>
    );
}
