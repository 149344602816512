export enum Languages {
    SK = 'sk',
    EN = 'en'
}

export const Language = (
    {language, setLanguage}: { language: Languages, setLanguage: (language: Languages) => void }
) => {
    return (
        <div className="flex items-center">
            {language === Languages.SK &&
                <button onClick={
                    () => {
                        setLanguage(Languages.EN)
                    }
                }><img className="w-8" src="./assets/slovak.png" alt="slovak"/></button>
            }
            {language === Languages.EN &&
                <button onClick={
                    () => {
                        setLanguage(Languages.SK)
                    }
                }><img className="w-8" src="./assets/english.png" alt="english"/></button>

            }
        </div>
    )
}

