import React, {ForwardedRef, forwardRef} from "react";
import {SocialLink} from "./profile/profile";
import {NavDataItem} from "./nav/custom-nav";

export interface ContactData {
    email: string;
    socialLinks: SocialLink[];
}

export const Contact = forwardRef(
    ({
         contactData,
         navDataItem
     }: {
        contactData: ContactData | undefined,
        navDataItem: NavDataItem | undefined
    }, ref: ForwardedRef<HTMLElement>) => {
        return (
            <section ref={ref} id={navDataItem?.href.replace("#","")} className="relative min-h-[74vh]">
                <p className="text-center">{navDataItem?.subTitle}</p>
                <h1 className="text-center text-5xl">{navDataItem?.name}</h1>
                <div className="flex flex-col gap-4 my-8">
                    <div className="flex h-12 gap-x-[0.5rem] items-center justify-center">
                        <div className="flex h-13 w-13 items-center justify-center">
                            <button onClick={() => window.open(`mailto:${contactData?.email}`)}>
                                <img
                                    src="./assets/email.png"
                                    alt="Email icon"
                                    className="cursor-pointer h-12 w-12 hover:h-10 hover:w-10"
                                />
                            </button>
                        </div>
                        <p><a href={`mailto:${contactData?.email}`}>{contactData?.email}</a></p>
                    </div>
                    {(contactData?.socialLinks || []).map((socialLink) => (
                        <div key={socialLink.name} className="flex h-12 gap-x-[0.5rem] justify-center items-center">
                            <div className="flex h-11 w-11 items-center justify-center">
                                <button onClick={() => window.location.href = socialLink.link}>
                                    <img
                                        src={`./assets/${socialLink.icon}`}
                                        alt={socialLink.alt}
                                        className="cursor-pointer h-10 w-10 hover:h-9 hover:w-9"
                                    />
                                </button>
                            </div>
                            <p><a href={socialLink.link}>{socialLink.name}</a></p>
                        </div>
                    ))}
                </div>
            </section>
        );
    })
