import React from "react";
import './skill.css'
import {ExperienceDataItem} from "./experience";

export const Skill = ({experienceDataItems}: {
    experienceDataItems: ExperienceDataItem[],
}) => {
    return (
        <div className="container">
            {
                experienceDataItems.map((experienceDataItem, index) => (
                    <div key={experienceDataItem.name} className="skill-box">
                        <span className="title">{experienceDataItem.name}</span>
                        <div className="skill-bar">
                            <span className="skill-per" style={{
                                width: experienceDataItem.level + "%",
                                animationDelay: (index / 100 * Math.random()) + 's'
                            }}>
                                <span
                                    className="tooltip">{experienceDataItem.level}%</span>
                            </span>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}
