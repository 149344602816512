export enum ArrowHrefPosition {
    TOP,
    BOTTOM

}

export const ArrowHref = ({href, position}: { href?: string, position: ArrowHrefPosition }) => {
    return href ? (
        <button className={`fixed h-8 w-8 flex justify-center align-middle items-center right-2 z-20 ${position === ArrowHrefPosition.TOP ? "top-2 icon-rotate180" : "bottom-2"}`} onClick={() => window.location.href = href}>
            <img
                src="./assets/arrow.png"
                alt="Arrow icon"
                className={"h-8 w-8 hover:h-6 hover:w-6"}
            />
        </button>
    ) : <></>
}
