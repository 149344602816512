import './custom-nav.css';
import {ForwardedRef, forwardRef} from "react";
import {Language, Languages} from "../language";

export interface NavData {
    title: string;
    navItems: NavDataItem[];
}

export interface NavDataItem {
    subTitle?: string;
    name: string;
    href: string;
}

export const CustomNav = forwardRef(
    ({navData, language, setLanguage}: { navData: NavData | undefined, language: Languages, setLanguage: (language: Languages) => void }, ref: ForwardedRef<HTMLElement>) => {
        const toggleMenu = () => {
            const hamburgerMenu = document.querySelector('.hamburger-menu .menu-links');
            hamburgerMenu?.classList.toggle('open');
        }

        return (
            <div id="home" className="nav-header">
                <nav ref={ref} id="desktop-nav">
                    <div className="logo">{navData?.title}</div>
                    <div className="flex flex-row gap-8">
                        <ul className="nav-links">
                            {
                                (navData?.navItems || []).map((navItem) => (
                                    <li key={`nav-${navItem?.href}`}><a href={navItem.href}>{navItem.name}</a></li>
                                ))
                            }
                        </ul>
                        <Language setLanguage={setLanguage} language={language}/>
                    </div>
                </nav>
                <nav id="hamburger-nav">
                    <div className="logo">{navData?.title}</div>
                    <div className="hamburger-menu">
                        <div className="flex flex-row gap-8 items-center">
                            <div className="hamburger-icon" onClick={() => toggleMenu()}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <Language setLanguage={setLanguage} language={language}/>
                        </div>
                        <div className="menu-links">
                            {
                                (navData?.navItems || []).map((navItem) => (
                                    <li key={`hamburger-${navItem?.href}`}><a href={navItem?.href}
                                                                              onClick={() => toggleMenu()}>{navItem.name}</a>
                                    </li>
                                ))
                            }
                        </div>

                    </div>
                </nav>
            </div>
        );
    }
)
